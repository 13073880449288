<template>
    <div>
        <div class="ef-footer">
                        <div class="ef-download-box">
                            <div class="ef-title">
                                <img src="../../assets/images/home_app_logo.png">
                                <div>
                                    <h1>{{ $t('message.qkljxcuks') }}</h1>
                                    <p>{{ $t('message.xzqxzb') }}</p>
                                </div>
                            </div>
                            <div @click="goUb()" class="ef-download">
                                <div class="donwloadBtn">
                                    <span>{{ $t('message.xzapp') }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="animate__fadeInUp" style="visibility: visible;">
                            <div @click="isOpen(0)" class="ef-link-nav" :class="{ isActive:isShow[0] }">
                                <a class="active">{{ $t('message.ubitapp') }}</a>
                                <a @click="serve()" :class="{ opacity:isShow[0] }">{{ $t('message.fwtk2') }}</a>
                                <a :class="{ opacity:isShow[0] }">{{ $t('message.foot_ystk') }}</a>
                                <img :class="{ trans:isShow[0] }" src="../../assets/images/top_s.png">
                            </div>
                            <div @click="isOpen(1)" class="ef-link-nav" :class="{ isActive:isShow[1] }">
                                <a class="active">{{ $t('message.foot_gs') }}</a>
                                <a @click="$router.push('/mobilde_aboutus')" :class="{ opacity:isShow[1] }">{{ $t('message.gywma') }}</a>
                                <img :class="{ trans:isShow[1] }" src="../../assets/images/top_s.png">
                            </div>
                            <div @click="isOpen(2)" class="ef-link-nav" :class="{ isActive:isShow[2] }">
                                <a class="active">{{ $t('message.home_kfzf1') }}</a>
                                <a @click="$router.push('/mobile_solution')" :class="{ opacity:isShow[2] }">{{ $t('message.home_jjfan') }}</a>
                                <a @click="$router.push('/mobile_scenario')" :class="{ opacity:isShow[2] }">{{ $t('message.home_yycj1') }}</a>
                                <a :class="{ opacity:isShow[2] }">{{ $t('message.home_kfwd1') }}</a>
                                <a @click="$router.push('/mobilde_assets')" :class="{ opacity:isShow[2]}">{{ $t('message.solution_zyxz') }}</a>
                                <img :class="{ trans:isShow[2]}" src="../../assets/images/top_s.png">
                            </div>
                            <div @click="isOpen(3)" class="ef-link-nav" :class="{ isActive:isShow[3] }">
                                <a class="active">{{ $t('message.bzzxx') }}</a>
                                <a @click="rookie()" :class="{ opacity:isShow[3] }">{{ $t('message.foot_xszn') }}</a>
                                <a @click="otc()" :class="{ opacity:isShow[3] }">{{ $t('message.foot_cjwt') }}</a>
                                <img :class="{ trans:isShow[3] }" src="../../assets/images/top_s.png">
                            </div>
                        </div>
                        <div class="ef-web-icon">
                            <a @click="febk()" target="_blank">
                                <img src="../../assets/images/fb.png">
                            </a>
                            <a @click="twitter()" target="_blank">
                                <img src="../../assets/images/twitter@2x.be7fef7.png">
                            </a>
                        </div>
<!--                        <div @click="openUrl()" class="online-custom-serve-fixed">-->
<!--                            <div  class="img-box">-->
<!--                                <img src="../../assets/images/service_icon.png">-->
<!--                             </div>-->
<!--                             <p >在线客服</p>-->
<!--                        </div>-->
                       
                    </div>
    </div>
</template>
<script>
export default {
    name: 'mobile_down',
    mounted() {
      const tidioScript = document.createElement('script');
      tidioScript.src = 'https://code.tidio.co/ucxujc9523heol9jhxk5wb9ygv5ibsy6.js';
      tidioScript.async = true;
      document.body.appendChild(tidioScript);
    },
    data() {
    return {
        isShow: [true, true, true, true],
    }
    },
    methods:{
        isOpen(index){
            this.isShow = this.isShow.map((value, i) => i === index ? !value : true);
        },
        febk(){
      window.open('https://www.facebook.com/people/%E4%BC%98%E5%B8%81-UBIT/100089898847703/','_blank')
    },
    goUb(){
        window.open('https://www.yubituser.com','_blank')
    },
    
    twitter(){
      window.open('https://twitter.com/UBIT88','_blank')
    },
        otc(){
      this.$router.push({ 
        path: '/helpList', // 路由路径
        query: { 
          code: 'otc' // 参数
        }
      })
    },
        serve(){
      this.$router.push({ 
        path: '/helpCont', // 路由路径
        query: { 
          code: 'serve' // 参数
        }
      })
    },
    secrecy(){
      this.$router.push({ 
        path: '/helpCont', // 路由路径
        query: { 
          code: 'secrecy' // 参数
        }
      })
    },
    rookie(){
      this.$router.push({ 
        path: '/helpList', // 路由路径
        query: { 
          code: 'rookie' // 参数
        }
      })
    },
    openUrl(){
        window.open('https://www.kf1703202002.com/chat/chatClient/chatbox.jsp?companyID=65350&configID=208&pagereferrer=UT','_blank')
    },
    }
}
</script>
<style lang="scss">
.ef-footer{
    background: linear-gradient(180deg,#111,#222);
    margin-top: 20px;
    .ef-download-box{
        height: 51.2vw;
        .ef-title{
            height: 26.667vw;
            margin: 0 5.333vw;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            img{
                height: 13.333vw;
                width: 13.333vw;
                margin-right: 2.133vw;
            }
            h1{
                font-size: 5.333vw;
                font-weight: 600;
                color: #ddba82;
                line-height: 7.467vw;
                padding-top: 20px;
            }
            p{
                height: 4.533vw;
                font-size: 3.2vw;
                font-weight: 400;
                color: #888683;
                line-height: 4.533vw;
            }
        }
        .ef-download{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            .donwloadBtn{
                width: 100%;
                margin: 0 4vw;
                height: 13.333vw;
                border-radius: 1.867vw;
                background: #ddba82;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                span{
                    color: #2d2c2b;
                    font-size: 3.733vw;
                    font-family: PingFangSC-Semibold,PingFang SC;
                    font-weight: 600;
                }
            }
        }
    }
    .ef-link-nav{
            background: #2d2c2b;
            border-radius: 2.133vw;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            margin: 0 5.333vw 3.2vw;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-align: center;
            position: relative;
            height: 25.6vw;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-transition: all .25s;
            transition: all .25s;
            a{
                text-align: center;
                font-size: 3.2vw;
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                -ms-flex-align: center;
                font-weight: 400;
                color: #ddba82;
                opacity: 1;
            }
            .active{
                color: #888683;
                width: 100%;
                font-size: 3.467vw;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
            }
            .opacity{
                opacity: 0;
                display: none;
            }
            img{
                position: absolute;
                right: 2.667vw;
                width: 1.867vw;
                height: 1.067vw;
                border: 2.667vw solid #2d2c2b;
                top: 1.067vw;
                -webkit-box-sizing: content-box;
                box-sizing: content-box;
            }
            .trans{
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
                -webkit-transition: all .2s;
                transition: all .2s;
            }
        }
        .isActive{
            height: 8.533vw;
        }
    .ef-web-icon{
        padding-top: 2.133vw;
        padding-bottom: 4.667vw;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        img{
            width: 8.533vw;
            height: 8.8vw;
            margin: 0 2.133vw;
        }
    }
    .online-custom-serve-fixed{
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: fixed;
        right: 2.667vw;
        bottom: 3.2vw;
        z-index: 9999999;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        cursor: pointer;
        .img-box{
            border-radius: 100%;
            background: #ddba82;
            -webkit-box-shadow: 0 1.333vw 1.333vw 1.333vw rgba(0,0,0,.2);
            box-shadow: 0 1.333vw 1.333vw 1.333vw rgba(0,0,0,.2);
            width: 10.667vw;
            height: 10.667vw;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            img{
                width: 5.333vw;
            }
        }
        p{
            margin-top: 2.133vw;
            font-size: 1.867vw;
            color: #ddba82;
            letter-spacing: 0;
            text-align: right;
            line-height: 1.867vw;
            font-weight: 400;
        }
    }
}
</style>
<template>
  <div>
    <div class="down-load-box" data-v-8a15108a data-v-3cdcf58c>
      <div class="ol-bg f-bc" data-v-8a15108a>
        <div class="main f-bc" data-v-8a15108a>
          <div class="info f-xc" data-v-8a15108a>
            <img alt="" class="logo" src="../assets/images/home_app_logo.png" data-v-8a15108a>
            <!-- <img alt="" class="logo" src="../assets../assets/images/home_app_logo.png"  data-v-8a15108a> -->
            <div class="text-b" data-v-8a15108a>
              <div class="des" data-v-8a15108a>
                {{ $t('message.qkljxcuks') }}
              </div>
              <div class="tips" data-v-8a15108a>
                {{ $t('message.xzqxzb') }}
              </div>
            </div>
          </div>
          <div class="down-way f-xc" data-v-8a15108a>
            <div id="qrcode" class="qr-code f-cc" data-v-8a15108a>
              <img id="qrCodeIco" src="../assets/images/home_app_logo.png" class="eo-qr-code-ico" data-v-8a15108a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-box" data-v-710ab180 data-v-3cdcf58c>
      <div class="main" data-v-710ab180>
        <div data-v-710ab180>
          <div class="menu" data-v-710ab180>
            <div class="head" data-v-710ab180>
              {{ $t('message.ubitapp') }}
            </div>
            <div class="item-text" data-v-710ab180>
              <span @click="serve()" data-v-710ab180>{{ $t('message.fwtk2') }}</span>
            </div>
            <div class="item-text" data-v-710ab180>
              <span @click="secrecy()" data-v-710ab180>{{ $t('message.foot_ystk') }}</span>
            </div>
          </div>
          <div class="menu" data-v-710ab180>
            <div class="head" data-v-710ab180>
              {{ $t('message.foot_gs') }}
            </div>
            <div class="item-text" data-v-710ab180>
              <span @click="$router.push('/aboutus')" data-v-710ab180>{{ $t('message.gywma') }}</span>
            </div>
            <!-- <div class="item-text" data-v-710ab180>
                    <span data-v-710ab180>加入我们</span>
                  </div> -->
            <!-- <div class="item-text" data-v-710ab180>
                    <span data-v-710ab180>最新公告</span>
                  </div> -->
          </div>
          <div class="menu" data-v-710ab180>
            <div class="head" data-v-710ab180>
              {{ $t('message.home_kfzf1') }}
            </div>
            <div @click="solution(1)" class="item-text" data-v-710ab180>
              <span data-v-710ab180>{{ $t('message.home_jjfan') }}</span>
            </div>
            <div @click="solution(2)" class="item-text" data-v-710ab180>
              <span data-v-710ab180>{{ $t('message.home_yycj1') }}</span>
            </div>
            <div @click="solution(3)" class="item-text" data-v-710ab180>
              <span data-v-710ab180>{{ $t('message.solution_kfwd') }}</span>
            </div>
            <!-- <div class="item-text" data-v-710ab180>
                    <span data-v-710ab180>资源下载</span>
                  </div> -->
          </div>
          <div class="menu" data-v-710ab180>
            <div class="head" data-v-710ab180>
              {{ $t('message.bzzxx') }}
            </div>
            <div class="item-text" data-v-710ab180>
              <span @click="rookie()" data-v-710ab180>{{ $t('message.foot_xszn') }}</span>
            </div>
            <div class="item-text" data-v-710ab180>
              <span @click="otc()" data-v-710ab180>{{ $t('message.foot_cjwt') }}</span>
            </div>
            <div class="item-text" data-v-710ab180>
              <span @click="sell()" data-v-710ab180>{{ $t('message.foot_qklzs') }}</span>
            </div>
          </div>
        </div>
        <div class="contact-box" data-v-710ab180>
          <div class="time" data-v-710ab180>
            {{ $t('message.foot_sjfx') }}
          </div>
          <div class="media" data-v-710ab180>
            <img @click="febk()"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAA3lBMVEUAAAD//////6ro0Yvdu4joxYvfv4rivYPdvIbhvIbevIXevoLhvoLgvIbevYTfuobdvIPfvIPgvYXevYTfvILfuoTdu4LdvITfuoPdu4Teu4Peu4TeuoPeu4Leu4Pdu4LdvIPeu4PevITeuoPdu4PeuoPeu4Peu4Pdu4Peu4Pdu4PduoLeu4Pdu4Leu4PduoPeu4Ldu4Peu4PeuoLeuoPduoPeu4Peu4Ldu4PeuoPeu4PduoLdu4Peu4LeuoPeu4Leu4Peu4Ldu4Peu4LduoLdu4Pdu4LeuoPeuoLduoKN0vLIAAAASXRSTlMAAgMLDxYYIyYqLi8zOT4/REhJTVhZWlt3eHt8goOEj5Cam5yeoKKkpqitrrG0t73Cw8fIydTY2dre4OPk5ebn6O/w9/n6+/z9FBdiVAAAATVJREFUSMft19lSwjAUBuDjgtW6IO5icMGVqqhUxQWKBjWc938hNTSUYZoccrxz+O+S6TeTSdKZPwA6i5U4UUhGJbEIIUsQoUeiwLg1iV6RpdQp9IzSMpDoHfm72ktkJPrZT2QlhAoPCrgf57NW7aC8u7d/eHr9ns40ICHVR3UhO/J6OtmGHuUe5oeuygAqoNzdDORBpOBrAXhwE3jwcYoJBTDhykAsierZ+UWtMyacNW5n9Njc8NO4Qhe94JuBq+gHWwZucOH2P4SyqXNr4Hp/3Hyi4BHkZ44Ll7lwiwvLXHjMhTdc+MyE018UfLnSOTGi2B/XJ3/HBP4Z9nhQucuDHbYh5sGGuyDZoXBXMjsM3SXQCiOidtqgrp2uomuBqkRV63xoqrWjzOfCrMzbnw+jcOj58A38grXSUdty7wAAAABJRU5ErkJggg=="
              alt data-v-710ab180>
            <img  @click="twitter()" src="../assets/images/twitter@2x.be7fef7.png" alt data-v-710ab180>
            <!-- <img src="../assets/images/medium@2x.999ff04.png" alt data-v-710ab180>
            <img src="../assets/images/reddit@2x.1017e9f.png" alt data-v-710ab180> -->
          </div>
        </div>
<!--        <div @click="openUrl()" class="online-custom-serve-fixed" data-v-710ab180>-->
<!--          <div class="img-box" data-v-710ab180>-->
<!--            <img src="../assets/images/service_icon.png" data-v-710ab180>-->
<!--          </div>-->
<!--          <p data-v-710ab180 >在线客服1</p>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  'name': 'footerbox',
    mounted() {
      const tidioScript = document.createElement('script');
      document.tidioChatLang = document.querySelector('html').getAttribute('lang');
      tidioScript.src = 'https://code.tidio.co/ucxujc9523heol9jhxk5wb9ygv5ibsy6.js';
      tidioScript.async = true;
      document.body.appendChild(tidioScript);
      tidioScript.onload = function() {
        window.tidioChatApi.on("ready", function() {
          window.tidioChatApi.open();
        });
      };
    },
    data() {
    return {
    };
  },
  methods:{
    openUrl(){
        window.open('https://www.kf1703202002.com/chat/chatClient/chatbox.jsp?companyID=65350&configID=208&pagereferrer=UT','_blank')
    },
    febk(){
      window.open('https://www.facebook.com/people/%E4%BC%98%E5%B8%81-UBIT/100089898847703/','_blank')
    },
    twitter(){
      window.open('https://twitter.com/UBIT88','_blank')
    },
    serve(){
      this.$router.push({ 
        path: '/helpCont', // 路由路径
        query: { 
          code: 'serve' // 参数
        }
      })
    },
    secrecy(){
      this.$router.push({ 
        path: '/helpCont', // 路由路径
        query: { 
          code: 'secrecy' // 参数
        }
      })
    },
    solution(id){
      this.$router.push({ 
        path: '/solution', // 路由路径
        query: { 
          id: id // 参数
        }
      })
    },
    rookie(){
      this.$router.push({ 
        path: '/helpList', // 路由路径
        query: { 
          code: 'rookie' // 参数
        }
      })
    },
    otc(){
      this.$router.push({ 
        path: '/helpList', // 路由路径
        query: { 
          code: 'otc' // 参数
        }
      })
    },
    sell(){
      this.$router.push({ 
        path: '/helpList', // 路由路径
        query: { 
          code: 'sell' // 参数
        }
      })
    }
  }
}

</script>
<style >

.down-load-box[data-v-8a15108a] {
  background: -webkit-gradient(linear, left bottom, left top, from(#181818), to(#201f1e));
  background: linear-gradient(1turn, #181818, #201f1e);
  border: 1px solid #3d3832;
  background-size: 100%;
  width: 100%;
  height: 196px
}

.down-load-box .ol-bg[data-v-8a15108a] {
  width: 100%;
  height: 100%
}

.down-load-box .info .logo[data-v-8a15108a] {
  width: 100px;
  height: 100px
}

.down-load-box .text-b[data-v-8a15108a] {
  margin-left: 20px;
  padding-bottom: 10px;
  position: relative
}

.down-load-box .text-b[data-v-8a15108a]:after {
  position: absolute;
  bottom: 0;
  content: "";
  width: 40px;
  height: 3px;
  background: #ddba82
}

.down-load-box .text-b .des[data-v-8a15108a] {
  width: 432px;
  font-size: 36px;
  font-weight: 600;
  color: #ddba82;
  line-height: 36px;
  margin-bottom: 15px
}

.down-load-box .text-b .tips[data-v-8a15108a] {
  font-size: 16px;
  line-height: 16px;
  color: #888683
}

.down-load-box .down-way .qr-code[data-v-8a15108a] {
  width: 120px;
  height: 120px;
  border-radius: 7px;
  background-color: #fff;
  background: url(../assets/images/code.png) no-repeat 100%/100%;
}

.down-load-box .down-way .eo-qr-code-ico[data-v-8a15108a] {
  position: absolute;
  width: 30px;
  height: 30px
}

.down-load-box .down-way .btn_down[data-v-8a15108a] {
  width: 184px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #2d2c2b;
  line-height: 21px;
  background-color: #ddba82;
  margin-right: 30px
}

.down-load-box .down-way .btn_down[data-v-8a15108a]:nth-child(2) {
  margin-right: 36px
}

.down-load-box .down-way .btn_down img[data-v-8a15108a] {
  width: 18px;
  margin-right: 10px
}
.footer-box[data-v-710ab180] {
  width: 100%;
  height: 256px;
  padding-top: 36px;
  padding-bottom: 22px;
  background: -webkit-gradient(linear, left top, left bottom, from(#111), to(#222));
  background: linear-gradient(180deg, #111, #222)
}

.footer-box .main[data-v-710ab180] {
  overflow: hidden
}

.footer-box .menu[data-v-710ab180] {
  width: 190px;
  float: left
}

.footer-box .menu .head[data-v-710ab180] {
  font-size: 18px;
  margin-bottom: 10px;
  color: #ddba82;
  line-height: 24px
}

.footer-box .menu .item-text[data-v-710ab180] {
  cursor: pointer;
  font-size: 14px;
  color: #888683;
  line-height: 32px
}

.footer-box .menu .item-text span[data-v-710ab180]:hover {
  color: #ddba82
}

.footer-box .contact-box[data-v-710ab180] {
  font-size: 14px;
  color: #ddba82;
  line-height: 19px
}

.footer-box .contact-box .online[data-v-710ab180] {
  padding: 9px 18px;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 7px;
  height: 40px;
  border-radius: 7px;
  border: 1px solid #ddba82
}

.footer-box .contact-box .online[data-v-710ab180]:hover {
  color: #2d2c2b;
  background: #ddba82
}

.footer-box .contact-box .online .service-icon[data-v-710ab180] {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  position: relative;
  top: 4px;
  display: inline-block
}

.footer-box .contact-box .time[data-v-710ab180] {
  margin-bottom: 9px;
  line-height: 32px;
  line-height: 24px;
  font-size: 18px
}

.footer-box .contact-box .time2[data-v-710ab180] {
  margin-top: 9px;
  line-height: 32px;
  color: #888683
}

.footer-box .contact-box .media[data-v-710ab180] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.footer-box .contact-box .media img[data-v-710ab180] {
  cursor: pointer;
  width: 28px;
  height: 28px;
  margin-right: 20px;
  opacity: 1
}

.footer-box .contact-box .media img[data-v-710ab180]:hover {
  opacity: .9
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import solution from '../views/solution.vue'
import download from '../views/download.vue'
import help from '../views/help.vue'
import helpList from '../views/helpList.vue'
import helpCont from '../views/helpCont.vue'
import aboutus from '../views/aboutus.vue'
import mobile_Home from '../views/mobile/mobile_home.vue'
import mobile_solution from '../views/mobile/mobile_solution.vue'
import mobile_scenario from '../views/mobile/mobile_scenario.vue'
import mobilde_document from '../views/mobile/mobilde_document.vue'
import mobilde_assets from '../views/mobile/mobilde_assets.vue'
import mobilde_download from '../views/mobile/mobilde_download.vue'
import mobilde_aboutus from '../views/mobile/mobilde_aboutus.vue'
Vue.use(VueRouter)

const
    ua = navigator.userAgent.toLocaleLowerCase(),
    mobileOn = ua.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/solution',
    name: 'solution',
    component: solution
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: aboutus
  },
  {
    path: '/download',
    name: 'download',
    component: download
  },
  {
    path: '/help',
    name: 'help',
    component: help
  },
  {
    path: '/helpList',
    name: 'helpList',
    component: helpList,
  },
  {
    path: '/helpCont',
    name: 'helpCont',
    component: helpCont,
  },
]

if (mobileOn) {
  routes.unshift({
    path: '/',
    name: 'mobile_Home',
    component: mobile_Home
  },{
    path: '/mobile_solution',
    name: 'mobile_solution',
    component: mobile_solution
  }
  ,{
    path: '/mobile_scenario',
    name: 'mobile_scenario',
    component: mobile_scenario
  },{
    path: '/mobilde_document',
    name: 'mobilde_document',
    component: mobilde_document
  }
  ,{
    path: '/mobilde_assets',
    name: 'mobilde_assets',
    component: mobilde_assets
  }
  ,{
    path: '/mobilde_download',
    name: 'mobilde_download',
    component: mobilde_download
  }
  ,{
    path: '/mobilde_aboutus',
    name: 'mobilde_aboutus',
    component: mobilde_aboutus
  }
  )
}

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
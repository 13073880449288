<template>
    <div class="help">
    <div class="site"> 
     <helpTop></helpTop>   
   <div class="site-content"> 
    <div class="content-area"> 
     <main class="site-main container"> 

      <div class="topic-feed"> 
       <div class="row"> 
        <div class="col-md-6"  v-for="(item,id) in list" > 
         <section class="topic"> 
          <header class="topic-header"> 
           <h2 class="topic-name">{{ item.listTit }}</h2> 
           <!-- <div class="topic-count">
             {{item.data.length}} 篇文章 
           </div>  -->
          </header> 
          <ul class="topic-article-feed u-plain-list" style="cursor: pointer;"> 
           <li v-for="(it,ix) in item.data" :key="ix" class="topic-article" @click="openCont(it)"> <h3 class="topic-article-title"> <a class="topic-article-link"> </a>{{ it.titel }}</h3> </li> 
          </ul> 
          <a @click="goList(item)" style="cursor: pointer;" class="topic-more button-arrow button-arrow-right">{{ $t('message.help_cksy') }}</a>
         </section> 
        </div> 
       </div> 
      </div> 
     </main> 
    </div> 
   </div> 
   <!-- <footer class="site-footer container"> 
    <div class="footer-social"> 
     <a class="footer-social-item footer-social-item-facebook" href="https://www.facebook.com/yibifuexchange" target="_blank" rel="noopener noreferrer" aria-label="Facebook"> <i class="icon icon-facebook"></i> </a> 
     <a class="footer-social-item footer-social-item-twitter" href="https://twitter.com/yibifuexchange" target="_blank" rel="noopener noreferrer" aria-label="Twitter"> <i class="icon icon-twitter"></i> </a> 
     <a class="footer-social-item footer-social-item-rss" href="https://feedly.com/i/subscription/feed/http://help.yibifu.one/rss/" target="_blank" rel="noopener noreferrer" aria-label="RSS"> <i class="icon icon-rss"></i> </a> 
    </div> 
    <div class="copyright">
      Powered by 
     <a href="https://ghost.org/" target="_blank">Ghost</a> 
    </div> 
   </footer>  -->
  </div> 
    </div>
</template>
<script>
import helpTop from '@/components/help_top.vue'
export default {
    name: 'help',
    components: {
        helpTop
    },
    data() {
    return {
        list:[
            {
                listTit:'A-OTC',
                data:[
                    {
                        titel:this.$t('message.help_rhmru'),
                        content:this.$t('message.help_zmmb11')+this.$t('message.help_zmmb12')+this.$t('message.help_zmmb13')+this.$t('message.help_zmmb14'),
                        link:[
                            {
                                h1:this.$t('message.help_zmmb11'),
                                image:require('@/assets/images/cont/buy_1.jpg')
                            },
                            {
                                h1:this.$t('message.help_zmmb12'),
                                image:require('@/assets/images/cont/buy_2.png'),
                                // image1:require('@/assets/images/cont/buy_3.jpg')
                            },
                            {
                                h1:this.$t('message.help_zmmb13'),
                                image:require('@/assets/images/cont/buy_4.jpg')
                            },
                            {
                                h1:this.$t('message.help_zmmb14'),
                                image:require('@/assets/images/cont/buy_5.jpg')
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_zmmb2'),
                        content:this.$t('message.help_zmmb21')+this.$t('message.help_zmmb22')+this.$t('message.help_zmmb23')+this.$t('message.help_zmmb24'),
                        link:[
                            {
                                h1:this.$t('message.help_zmmb21'),
                                image:require('@/assets/images/cont/sell_1.jpg')
                            },
                            {
                                h1:this.$t('message.help_zmmb22'),
                                image:require('@/assets/images/cont/sell_2.png'),
                                // image1:require('@/assets/images/cont/sell_3.jpg')
                            },
                            {
                                h1:this.$t('message.help_zmmb23'),
                                image:require('@/assets/images/cont/sell_4.jpg')
                            },
                            {
                                h1:this.$t('message.help_zmmb24'),
                                image:require('@/assets/images/cont/sell_5.jpg')
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_zmmb3'),
                        content:this.$t('message.help_zmmb30'),
                        link:[
                            {
                                h1:this.$t('message.help_zmmb30'),
                            },
                            {
                                h1:this.$t('message.help_zmmb31'),
                            },
                            {
                                h1:this.$t('message.help_zmmb32'),
                            },
                            {
                                h1:this.$t('message.help_zmmb33'),
                                image:require('@/assets/images/cont/imga1.jpg'),
                                image1:require('@/assets/images/cont/imga2.jpg')
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_zmmb4'),
                        content:this.$t('message.help_zmmb41'),
                        link:[
                            {
                                h1:this.$t('message.help_zmmb41'),
                            },
                            {
                                h1:this.$t('message.help_zmmb42'),
                                style:'red'
                            },
                            {
                                image:require('@/assets/images/cont/passy.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_zmmb5'),
                        content:this.$t('message.help_zmmb51')+this.$t('message.help_zmmb52')+this.$t('message.help_zmmb53'),
                        link:[
                            {
                                h1:this.$t('message.help_zmmb51'),
                                image:require('@/assets/images/cont/revise_1.jpg')
                            },
                            {
                                h1:this.$t('message.help_zmmb52'),
                                image:require('@/assets/images/cont/revise_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_zmmb53'),
                            },
                            {
                                h1:this.$t('message.help_zmmb54'),
                                style:'red',
                                image:require('@/assets/images/cont/revise_3.jpg'),
                                image:require('@/assets/images/cont/revise_4.jpg'),
                            },
                        ]
                    },
                ]
            },
            {
                listTit:this.$t('message.help_jyxg'),
                data:[
                    {
                        titel:this.$t('message.help_jyxg1'),
                        link:[
                        {
                                h1:this.$t('message.help_jyxg11'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_jyxg2'),
                        link:[
                        {
                                h1:this.$t('message.help_jyxg21'),
                            },
                        ]
                    },
                    ]
            },
            {
                listTit:this.$t('message.help_zhxg'),
                data:[
                    {
                        titel:this.$t('message.help_zhxg1'),
                        link:[
                        {
                            h1:this.$t('message.help_zhxg11'),
                        },
                        {
                            h1:this.$t('message.help_zhxg12'),
                        },
                        {
                            h1:this.$t('message.help_zhxg13'),
                        },
                        {
                            h1:this.$t('message.help_zhxg14'),
                            image:require('@/assets/images/cont/zh_1.jpg'),
                        },
                        ]
                    },
                    {
                        titel:this.$t('message.help_zhxg2'),
                        link:[
                            {
                                h1:this.$t('message.help_zhxg21'),
                                image:require('@/assets/images/cont/zh_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_zhxg22'),
                                image:require('@/assets/images/cont/zh_3.jpg'),
                            },
                            {
                                h1:this.$t('message.help_zhxg23'),
                                h1:this.$t('message.help_zhxg24'),
                                image:require('@/assets/images/cont/zh_4.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_zhxg3'),
                        link:[
                        {
                                h1:this.$t('message.help_zhxg31'),
                                image:require('@/assets/images/cont/zh_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_zhxg32'),
                                image:require('@/assets/images/cont/zh_3.jpg'),
                            },
                            {
                                h1:this.$t('message.help_zhxg33'),
                                h1:this.$t('message.help_zhxg34'),
                                image:require('@/assets/images/cont/zh_4.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_bdgg4'),
                        link:[
                        {
                                h1:this.$t('message.help_bdgg41'),
                                image:require('@/assets/images/cont/zh_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_bdgg42'),
                                image:require('@/assets/images/cont/zh_3.jpg'),
                            },
                            {
                                h1:this.$t('message.help_bdgg43'),
                                image:require('@/assets/images/cont/zh_7.jpg'),
                                image:require('@/assets/images/cont/zh_8.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_bdyhk5'),
                        link:[
                        {
                                h1:this.$t('message.help_bdyhk51'),
                                image:require('@/assets/images/cont/zh_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_bdyhk52'),
                                image:require('@/assets/images/cont/zh_9.jpg'),
                            },
                            {
                                h1:this.$t('message.help_bdyhk53'),
                                image:require('@/assets/images/cont/zh_10.jpg'),
                            },
                            {
                                h1:this.$t('message.help_bdyhk54'),
                                image:require('@/assets/images/cont/zh_11.jpg'),
                            },
                            {
                                h1:this.$t('message.help_bdyhk55'),
                                image:require('@/assets/images/cont/zh_12.jpg'),
                            },
                        ]
                    },
                ]
            },
            {
                listTit:this.$t('message.help_tcwt'),
                data:[
                    {
                        titel:this.$t('message.help_tcwt1'),
                        link:[
                        {
                                h1:this.$t('message.help_tcwt11'),
                                image:require('@/assets/images/cont/cb_1.jpg'),
                            },
                            {
                                h1:this.$t('message.help_tcwt12'),
                                image:require('@/assets/images/cont/cb_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_tcwt13'),
                                image:require('@/assets/images/cont/cb_3.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_tcwt2'),
                        link:[
                        {
                                h1:this.$t('message.help_tcwt21'),
                                image:require('@/assets/images/cont/cb_4.jpg'),
                            },
                            {
                                h1:this.$t('message.help_tcwt22'),
                                image:require('@/assets/images/cont/cb_5.jpg'),
                            },
                            {
                                h1:this.$t('message.help_tcwt23'),
                                image:require('@/assets/images/cont/cb_6.jpg'),
                            },
                            {
                                h1:this.$t('message.help_tcwt24'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_tcwt3'),
                        link:[
                        {
                                h1:this.$t('message.help_tcwt31'),
                                image:require('@/assets/images/cont/cb_7.jpg'),
                            },
                            {
                                h1:this.$t('message.help_tcwt32'),
                                image:require('@/assets/images/cont/cb_8.jpg'),
                            },
                            {
                                h1:this.$t('message.help_tcwt33'),
                                image:require('@/assets/images/cont/cb_9.jpg'),
                            },
                            {
                                h1:this.$t('message.help_tcwt34'),
                                image:require('@/assets/images/cont/cb_10.jpg'),
                                image:require('@/assets/images/cont/cb_11.jpg'),
                            },
                        ]
                    },
                    ]
            },
            {
                listTit:this.$t('message.help_exszn'),
                data:[
                    {
                        titel:this.$t('message.help_exszna1'),
                        link:[
                        {
                            h1:this.$t('message.help_exszna11'),
                        },
                        {
                            h1:this.$t('message.help_exszna12'),
                        },
                        {
                            h1:this.$t('message.help_exszna13'),
                        },
                        {
                            h1:this.$t('message.help_exszna14'),
                            image:require('@/assets/images/cont/zh_1.jpg'),
                        },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn2'),
                        link:[
                        {
                                h1:this.$t('message.help_exszn21'),
                                image:require('@/assets/images/cont/zh_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_exszn22'),
                                image:require('@/assets/images/cont/zh_3.jpg'),
                            },
                            {
                                h1:this.$t('message.help_exszn23'),
                                h1:this.$t('message.help_exszn24'),
                                image:require('@/assets/images/cont/zh_4.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn3'),
                        link:[
                        {
                                h1:this.$t('message.help_exszn31'),
                                image:require('@/assets/images/cont/zh_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_exszn32'),
                                image:require('@/assets/images/cont/zh_3.jpg'),
                            },
                            {
                                h1:this.$t('message.help_exszn33'),
                                h1:this.$t('message.help_exszn34'),
                                image:require('@/assets/images/cont/zh_4.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn4'),
                        link:[
                        {
                                h1:this.$t('message.help_exszn41'),
                                image:require('@/assets/images/cont/zh_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_exszn42'),
                                image:require('@/assets/images/cont/zh_3.jpg'),
                            },
                            {
                                h1:this.$t('message.help_exszn43'),
                                image:require('@/assets/images/cont/zh_7.jpg'),
                                image:require('@/assets/images/cont/zh_8.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn5'),
                        link:[
                            {
                                h1:this.$t('message.help_exszn51'),
                            },
                            {
                                h1:this.$t('message.help_exszn52'),
                                style:'red'
                            },
                            {
                                image:require('@/assets/images/cont/passy.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn6'),
                        link:[
                            {
                                h1:this.$t('message.help_exszn61'),
                                image:require('@/assets/images/cont/revise_1.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn62'),
                                image:require('@/assets/images/cont/revise_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_exszn63'),
                            },
                            {
                                h1:this.$t('message.help_exszn64'),
                                style:'red',
                                image:require('@/assets/images/cont/revise_3.jpg'),
                                image:require('@/assets/images/cont/revise_4.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn7'),
                        link:[
                            {
                                image:require('@/assets/images/cont/kf_1.jpg')
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn8'),
                        link:[
                            {
                                h1:this.$t('message.help_exszn81'),
                                image:require('@/assets/images/cont/mb_1.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn82'),
                                image:require('@/assets/images/cont/mb_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_exszn83'),
                                image:require('@/assets/images/cont/mb_3.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn9'),
                        link:[
                            {
                                h1:this.$t('message.help_exszn91'),
                                image:require('@/assets/images/cont/zc_1.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn92'),
                                image:require('@/assets/images/cont/zc_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_exszn93'),
                                image:require('@/assets/images/cont/zc_3.jpg'),
                                image:require('@/assets/images/cont/zc_4.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn10'),
                        link:[
                            {
                                h1:this.$t('message.help_exszn101'),
                                image:require('@/assets/images/cont/buy_1.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn102'),
                                image:require('@/assets/images/cont/buy_2.png'),
                                // image1:require('@/assets/images/cont/buy_3.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn103'),
                                image:require('@/assets/images/cont/buy_4.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn104'),
                                image:require('@/assets/images/cont/buy_5.jpg')
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn11'),
                        link:[
                            {
                                h1:this.$t('message.help_exszn111'),
                                image:require('@/assets/images/cont/sell_1.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn112'),
                                image:require('@/assets/images/cont/sell_2.png'),
                                // image1:require('@/assets/images/cont/sell_3.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn113'),
                                image:require('@/assets/images/cont/sell_4.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn114'),
                                image:require('@/assets/images/cont/sell_5.jpg')
                            },
                        ]
                    },
                    ]
            },
            {
                listTit:this.$t('message.help_fxytk'),
                data:[
                    {
                        titel:this.$t('message.help_fxytk1'),
                        code:'serve',
                        link:''
                    },
                    {
                        titel:this.$t('message.help_fxytk2'),
                        code:'secrecy',
                        link:''
                    },
                    ]
            },
        ],
    };
  },
  methods:{
    goList(item){
        this.$router.push({
            path:'/helpList',
        })
        this.$store.commit('updateList', item);
    },
    openCont(item){
        this.$router.push({
            path:'/helpCont',
        })
        this.$store.commit('updateCont', item);
    }
  },
  mounted(){
    this.$store.commit('updatelistData', this.list);
  },
}
</script>
<style >
@import '../assets/css/screen.css';
body, html{
    background:#fff;
}
.post-feed{
    position: relative;
    display: flex;
    padding: 35px 0 33px;
    flex-wrap: wrap;
}
.related-posts{
    margin-top: 60px;
}
.related-title{
    margin-bottom: 0;
    color: #999;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.post-title{
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
}
.content-area{
    flex-grow: 1;
    padding-top: 60px;
    padding-bottom: 30px;
}
.post-header{
    margin-right: auto;
    margin-left: auto;
    padding-right: 4vw;
    padding-left: 4vw;
    max-width: calc(1130px + 8vw);
    width: 100%;

}
.post-title{
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 500;
}
.kg-canvas{
    margin-top: 45px;
}
.kg-image{
    margin-right: auto;
    margin-left: auto;
    display: block;
    max-width: 100%;
    height: auto;
}
.site-content{
    flex-grow: 1;
    padding-top: 60px;
    padding-bottom: 30px;
}
.term{
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}
.containerBox{
    margin-right: auto;
    margin-left: auto;
    padding-right: 4vw;
    padding-left: 4vw;
    max-width: calc(1130px + 8vw);
    width: 100%;
    list-style-type: none;
}
.containerBox li {
    display: flex;
    padding: 35px 0 33px;
    border-bottom: 1px solid #e0dddd;
    flex-wrap: wrap;
    cursor: pointer;
}
.term-name{
    margin-bottom: 0;
    font-size: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1.3;
    font-weight: 700;
    color: #6c5ce7;
}
.post-title{
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
}
.post-excerpt{
    color:#999;
    font-size: 15px;
    line-height: 1.5;
}
</style>
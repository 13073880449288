<template>
    <div>
        <helpTop></helpTop>
        <div class="site-content">
    <div class="term containerBox">
        <h1 class="term-name">{{ showList.listTit }}</h1>
    </div>
        
        <ul class="containerBox ">
            <li v-for="(itm,id) in showList.data" @click="openCont(itm)">
                <span class="post-title">{{ itm.titel}}</span>
                <p class="post-excerpt">{{ itm.content }}</p>
            </li>
        </ul>
   </div>
    </div>
</template>
<script>
import helpTop from '@/components/help_top.vue'
import { mapState } from 'vuex';
export default {
    components:{
        helpTop
    },
    computed: {
        ...mapState({
            list:state => state.list
        }),
    },
    name: 'helpCont',
    created() {
    this.showList = this.list; 
  },

    data() {
        return{
            showList:null
        }
    },
    mounted(){
    if(this.$route.query.code =='rookie'){
      this.showList = {
                listTit:$t('message.foot_xszn'),//'E-新手指南',
                data:[
                    {
                        titel:this.$t('message.help_zhxg1'),
                        link:[
                        {
                            h1:this.$t('message.help_zhxg11'),
                        },
                        {
                            h1:this.$t('message.help_zhxg12'),
                        },
                        {
                            h1:this.$t('message.help_zhxg13'),
                        },
                        {
                            h1:this.$t('message.help_zhxg14'),
                            image:require('@/assets/images/cont/zh_1.jpg'),
                        },
                        ]
                    },
                    {
                        titel:this.$t('message.help_zhxg2'),
                        link:[
                        {
                                h1:this.$t('message.help_zhxg21'),
                                image:require('@/assets/images/cont/zh_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_zhxg22'),
                                image:require('@/assets/images/cont/zh_3.jpg'),
                            },
                            {
                                h1:this.$t('message.help_zhxg23'),
                                h1:this.$t('message.help_zhxg24'),
                                image:require('@/assets/images/cont/zh_4.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_zhxg3'),
                        link:[
                        {
                                h1:this.$t('message.help_zhxg31'),
                                image:require('@/assets/images/cont/zh_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_zhxg31'),
                                image:require('@/assets/images/cont/zh_3.jpg'),
                            },
                            {
                                h1:this.$t('message.help_zhxg33'),
                                h1:this.$t('message.help_zhxg34'),
                                image:require('@/assets/images/cont/zh_4.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_bdgg4'),
                        link:[
                        {
                                h1:this.$t('message.help_bdgg41'),
                                image:require('@/assets/images/cont/zh_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_bdgg42'),
                                image:require('@/assets/images/cont/zh_3.jpg'),
                            },
                            {
                                h1:this.$t('message.help_bdgg43'),
                                image:require('@/assets/images/cont/zh_7.jpg'),
                                image:require('@/assets/images/cont/zh_8.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn5'),
                        link:[
                            {
                                h1:this.$t('message.help_exszn51'),
                            },
                            {
                                h1:this.$t('message.help_exszn52'),
                                style:'red'
                            },
                            {
                                image:require('@/assets/images/cont/passy.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn6'),
                        link:[
                            {
                                h1:this.$t('message.help_exszn61'),
                                image:require('@/assets/images/cont/revise_1.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn62'),
                                image:require('@/assets/images/cont/revise_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_exszn63'),
                            },
                            {
                                h1:this.$t('message.help_exszn64'),
                                style:'red',
                                image:require('@/assets/images/cont/revise_3.jpg'),
                                image:require('@/assets/images/cont/revise_4.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn7'),
                        link:[
                            {
                                image:require('@/assets/images/cont/kf_1.jpg')
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn8'),
                        link:[
                            {
                                h1:this.$t('message.help_exszn81'),
                                image:require('@/assets/images/cont/mb_1.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn82'),
                                image:require('@/assets/images/cont/mb_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_exszn83'),
                                image:require('@/assets/images/cont/mb_3.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn9'),
                        link:[
                            {
                                h1:this.$t('message.help_exszn91'),
                                image:require('@/assets/images/cont/zc_1.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn92'),
                                image:require('@/assets/images/cont/zc_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_exszn93'),
                                image:require('@/assets/images/cont/zc_3.jpg'),
                                image:require('@/assets/images/cont/zc_4.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn10'),
                        link:[
                            {
                                h1:this.$t('message.help_exszn101'),
                                image:require('@/assets/images/cont/buy_1.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn102'),
                                image:require('@/assets/images/cont/buy_2.png'),
                                // image1:require('@/assets/images/cont/buy_3.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn103'),
                                image:require('@/assets/images/cont/buy_4.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn104'),
                                image:require('@/assets/images/cont/buy_5.jpg')
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_exszn11'),
                        link:[
                            {
                                h1:this.$t('message.help_exszn111'),
                                image:require('@/assets/images/cont/sell_1.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn112'),
                                image:require('@/assets/images/cont/sell_2.png'),
                                // image1:require('@/assets/images/cont/sell_3.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn113'),
                                image:require('@/assets/images/cont/sell_4.jpg')
                            },
                            {
                                h1:this.$t('message.help_exszn114'),
                                image:require('@/assets/images/cont/sell_5.jpg')
                            },
                        ]
                    },
                    ]
      }
    }
    if(this.$route.query.code =='otc'){
        this.showList =  {
                listTit:this.$t('message.foot_cjwt'),
                data:[
                    {
                        titel:this.$t('message.help_rhmru'),
                        content:this.$t('message.help_zmmb11')+this.$t('message.help_zmmb12')+this.$t('message.help_zmmb13')+this.$t('message.help_zmmb14'),
                        link:[
                            {
                                h1:this.$t('message.help_zmmb11'),
                                image:require('@/assets/images/cont/buy_1.jpg')
                            },
                            {
                                h1:this.$t('message.help_zmmb12'),
                                image:require('@/assets/images/cont/buy_2.png'),
                                // image1:require('@/assets/images/cont/buy_3.jpg')
                            },
                            {
                                h1:this.$t('message.help_zmmb13'),
                                image:require('@/assets/images/cont/buy_4.jpg')
                            },
                            {
                                h1:this.$t('message.help_zmmb14'),
                                image:require('@/assets/images/cont/buy_5.jpg')
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_zmmb2'),
                        content:this.$t('message.help_zmmb21')+this.$t('message.help_zmmb22')+this.$t('message.help_zmmb23')+this.$t('message.help_zmmb24'),
                        link:[
                            {
                                h1:this.$t('message.help_zmmb21'),
                                image:require('@/assets/images/cont/sell_1.jpg')
                            },
                            {
                                h1:this.$t('message.help_zmmb22'),
                                image:require('@/assets/images/cont/sell_2.png'),
                                // image1:require('@/assets/images/cont/sell_3.jpg')
                            },
                            {
                                h1:this.$t('message.help_zmmb23'),
                                image:require('@/assets/images/cont/sell_4.jpg')
                            },
                            {
                                h1:this.$t('message.help_zmmb42'),
                                image:require('@/assets/images/cont/sell_5.jpg')
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_zmmb3'),
                        content:this.$t('message.help_zmmb30')+this.$t('message.help_zmmb31')+this.$t('message.help_zmmb32')+this.$t('message.help_zmmb33'),
                        link:[
                            {
                                h1:this.$t('message.help_zmmb30'),
                            },
                            {
                                h1:this.$t('message.help_zmmb31'),
                            },
                            {
                                h1:this.$t('message.help_zmmb32'),
                            },
                            {
                                h1:this.$t('message.help_zmmb33'),
                                image:require('@/assets/images/cont/imga1.jpg'),
                                image1:require('@/assets/images/cont/imga2.jpg')
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_zmmb4'),
                        content:this.$t('message.help_zmmb41')+this.$t('message.help_zmmb42'),
                        link:[
                            {
                                h1:this.$t('message.help_zmmb41'),
                            },
                            {
                                h1:this.$t('message.help_zmmb42'),
                                style:'red'
                            },
                            {
                                image:require('@/assets/images/cont/passy.jpg'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_zmmb5'),
                        content:this.$t('message.help_zmmb51')+this.$t('message.help_zmmb52')+this.$t('message.help_zmmb53')+this.$t('message.help_zmmb54'),
                        link:[
                            {
                                h1:this.$t('message.help_zmmb51'),
                                image:require('@/assets/images/cont/revise_1.jpg')
                            },
                            {
                                h1:this.$t('message.help_zmmb52'),
                                image:require('@/assets/images/cont/revise_2.jpg'),
                            },
                            {
                                h1:this.$t('message.help_zmmb53'),
                            },
                            {
                                h1:this.$t('message.help_zmmb54'),
                                style:'red',
                                image:require('@/assets/images/cont/revise_3.jpg'),
                                image:require('@/assets/images/cont/revise_4.jpg'),
                            },
                        ]
                    },
                ]
            }
    }
    if(this.$route.query.code =='sell'){
        this.showList =  {
                listTit:this.$t('message.help_jyxg'),
                data:[
                    {
                        titel:this.$t('message.help_jyxg1'),
                        link:[
                        {
                                h1:this.$t('message.help_jyxg11'),
                            },
                        ]
                    },
                    {
                        titel:this.$t('message.help_jyxg2'),
                        link:[
                        {
                                h1:this.$t('message.help_jyxg21'),
                            },
                        ]
                    },
                    ]
            }
    }
  },
    methods:{
        openCont(item) {
            this.$router.push({
                path: '/helpCont',
            })
            this.$store.commit('updateCont', item);
        }
    },
    watch:{
        list(newValue, oldValue){
            this.showList = newValue
        }
    }
}
</script>
<style >
@import '../assets/css/screen.css';
.post-feed{
    position: relative;
    display: flex;
    padding: 35px 0 33px;
    flex-wrap: wrap;
}
.related-posts{
    margin-top: 60px;
}
.related-title{
    margin-bottom: 0;
    color: #999;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.post-title{
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
}
.content-area{
    flex-grow: 1;
    padding-top: 60px;
    padding-bottom: 30px;
}
.post-header{
    margin-right: auto;
    margin-left: auto;
    padding-right: 4vw;
    padding-left: 4vw;
    max-width: calc(1130px + 8vw);
    width: 100%;

}
.post-title{
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 500;
}
.kg-canvas{
    margin-top: 45px;
}
.kg-image{
    margin-right: auto;
    margin-left: auto;
    display: block;
    max-width: 100%;
    height: auto;
}
.site-content{
    flex-grow: 1;
    padding-top: 60px;
    padding-bottom: 30px;
}
.term{
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}
.containerBox{
    margin-right: auto;
    margin-left: auto;
    padding-right: 4vw;
    padding-left: 4vw;
    max-width: calc(1130px + 8vw);
    width: 100%;
    list-style-type: none;
}
.containerBox li {
    display: flex;
    padding: 35px 0 33px;
    border-bottom: 1px solid #e0dddd;
    flex-wrap: wrap;
    cursor: pointer;
}
.term-name{
    margin-bottom: 0;
    font-size: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1.3;
    font-weight: 700;
    color: #6c5ce7;
}
.post-title{
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
}
.post-excerpt{
    color:#999;
    font-size: 15px;
    line-height: 1.5;
}
</style>
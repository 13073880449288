import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import en from './locales/en.json'
import zh from './locales/zh.json'

Vue.config.productionTip = false

Vue.use(VueI18n)

const messages = {
  en,
  zh
}

// 获取浏览器语言
const browserLanguage = navigator.language || navigator.userLanguage
const defaultLanguage = browserLanguage.includes('en') ? 'en' : 'zh'

const i18n = new VueI18n({
  locale: defaultLanguage, // 设置默认语言
  messages // 设置语言信息
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

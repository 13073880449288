<template>
    <div class="mobilde_aboutus">
        <div class="ef-container">
            <top_mobile></top_mobile>
            <div class="request-page">
                <div class="openpay-head-title">
                    <div class="openpay-head-title-icon">
                    </div>
                    <div class="openpay-head-title-text">
                        <strong>{{ $t('message.gywma') }}</strong>
                    </div>
                </div>
                <div class="openpay-menu">
                        <a class="menu-item active" >{{ $t('message.gywma') }}</a>
                </div>
                <div class="logo">
                    <img src="../../assets/images/home_app_logo.png">
                </div>
                <h1 class="title">{{ $t('message.gywma') }}</h1>
                <p class="detail">
                  {{ $t('message.ubitjs') }}

  </p>
  <p class="detail">
    {{ $t('message.aqbza5') }}

  </p>
            </div>
        </div>
        <footer_mobile></footer_mobile>
    </div>
</template>
<script>
import top_mobile from "@/components/mobile/mobile_top.vue"
import footer_mobile from "@/components/mobile/mobile_down.vue"
export default {
    name: 'mobilde_aboutus',
    components: {
        top_mobile,
        footer_mobile,
  },
  data() {
    return {
    }
    },
}
</script>
<style lang="scss">
.mobilde_aboutus{
    .ef-container{
        background: linear-gradient(180deg,#111,#222);
        .request-page{
            padding-bottom: 24vw;
            .openpay-head-title{
                .openpay-head-title-icon{
                    background-image: url('../../assets/images/current_icon_gywm.png');
                    visibility: visible;
                    width: 12.8vw;
                    height: 12.8vw;
                    margin-right: 2.667vw;
                    background-size: 100% 100%;
                }
                
                height: 24.533vw;
                padding: 0 5.333vw 2.733vw;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                .openpay-head-title-text{
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    strong{
                        color: #ddba82;
                        font-size: 5.333vw;
                        font-weight: 600;
                    }
                    span{
                        color: #888683;
                        font-size: 3.2vw;
                        font-weight: 400;
                        margin-top: 0.8vw;
                    }
                }
            }
            .openpay-menu{
                height: 12.8vw;
                z-index: 1;
                position: relative;
                overflow: hidden;
                opacity: .85;
                border: 1px solid #3d3832;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-pack: distribute;
                justify-content: space-around;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                .menu-item {
                        font-size: 3.467vw;
                        font-weight: 400;
                        padding: 0 0.8vw;
                        color: #888683;
                        text-decoration: none;
                }
                .active{
                    color: #ddba82;
                }
            }
            .logo{
                width: 23.467vw;
                height: 23.467vw;
                margin: 10.667vw auto 5.067vw;
                display: flex;
                justify-content: center;
                img{
                    width: 100%;
                }
            }
            .title{
                font-size: 4.8vw;
                color: #ddba82;
                line-height: 6.4vw;
                font-weight: 400;
                margin-bottom: 13.333vw;
                text-align: center;
            }
            .detail{
                font-size: 4.267vw;
    color: #888683;
    line-height: 8.533vw;
    padding: 0 8vw 8vw;
            }
        }
    }
    .ef-footer{
        margin: 0;
    }
}
</style>
<template>
    <div class="mobile_solution">
        <div class="ef-container">
            <top_mobile></top_mobile>
            <div class="request-page">
                <div class="openpay-head-title">
                    <div class="openpay-head-title-icon">
                    </div>
                    <div class="openpay-head-title-text">
                        <strong>{{ $t('message.solution_yjjr') }}</strong>
                        <span>{{ $t('message.solution_ljkssk') }}</span>
                    </div>
                </div>
              <div class="openpay-menu">
                <a @click="$router.push('/mobile_solution')" class="menu-item">{{ $t('message.home_jjfan') }}</a>
                <a @click="$router.push('/mobile_scenario')" class="menu-item ">{{ $t('message.solution_yycj') }}</a>
                <a @click="$router.push('/mobilde_document')" class="menu-item">{{ $t('message.solution_kfwd') }}</a>
                <a class="menu-item active">{{ $t('message.solution_zyxz') }}</a>
              </div>
                <div class="ef-title">
                    <h1>{{ $t('message.solution_sklc') }}</h1>
                    <span class="ef-line"></span>
                </div>
                <div class="ef-detail-img">
                    <img :src="eo_flow_img" class="detail-img-con">
                    <div class="icons">
                        <img src="../../assets/images/right.png" class="icon">
                        <p>{{ $t('message.solution_zyhdck') }}</p>
                        <img class="icon" src="../../assets/images/left.png">
                    </div>
                </div>
                <div class="ef-title">
                    <h1>{{ $t('message.solution_ksjc') }}</h1>
                    <span class="ef-line"></span>
                </div>
                <div class="ef-desc">
                    <div class="ef-desc-item">
                        <h1>
                            <i>01</i>
                            <span>{{ $t('message.home_kfzf1') }}</span>
                        </h1>
                        <div class="ef-img-box">
                            <img class="ef-0" src="../../assets/images/img_sdk.png">
                        </div>
                        <p>{{ $t('message.home_rgjnjmhblj') }}</p>
                        <div class="ef-icons">
                            <img src="../../assets/images/pt_android.png"/>
                            <img class="apple" src="../../assets/images/pt_apple.png"/>
                            <img class="h5" src="../../assets/images/pt_html5.png"/>
                        </div>
                        <a class="ef-map">
                            <div class="ef-map-box">
                                <img src="../../assets/images/bottom.png"/>
                                <span>{{ $t('message.solution_zyxz') }}</span>
                            </div>
                        </a>
                    </div>
                    <div class="ef-desc-item">
                        <h1>
                            <i>02</i>
                            <span>{{ $t('message.solution_gddzhjk') }}</span>
                        </h1>
                        <div class="ef-img-box">
                            <img class="ef-1" src="../../assets/images/img_api.png">
                        </div>
                        <p>{{ $t('message.solution_wmtgffjk') }}</p>
                        <a class="ef-map">
                            <div class="ef-map-box">
                                <img src="../../assets/images/box.png"/>
                                <span>{{ $t('message.home_kfwd1') }}</span>
                            </div>
                        </a>
                    </div>
                    <div class="ef-desc-item">
                        <h1>
                            <i>03</i>
                            <span>{{ $t('message.solution_shglpt') }}</span>
                        </h1>
                        <div class="ef-img-box">
                            <img class="ef-2" src="../../assets/images/otc_img03.png">
                        </div>
                        <p>{{ $t('message.solution_glzjjmzc') }}</p>
                    </div>
                </div>
                <div class="ef-title">
                    <h1>{{ $t('message.solution_hzlc') }}</h1>
                    <span class="ef-line"></span>
                </div>
                <div class="step-img">
                    <img src="../../assets/images/step.png">
                </div>
            </div>
            <footer_mobile></footer_mobile>
        </div>
    </div>
</template>
<script>
import top_mobile from "@/components/mobile/mobile_top.vue"
import footer_mobile from "@/components/mobile/mobile_down.vue"
export default {
    name: 'mobile_solution',
    components: {
        footer_mobile,
        top_mobile
  },
    data() {
    return {
        isbox:true,
    }
    },
  computed: {
    eo_flow_img() {
      return require(`@/assets/images/${this.$t('message.eo_flow_img')}`);
    }
  },
    methods:{
    }
}
</script>
<style lang="scss">
html,body{
    height: 100%;
}
h1, h2, h3, h4, h5, h6{
    margin: 0;
}
.mobile_solution{
    overflow: hidden;
    .ef-container{
        margin: 0 auto;
        min-height: 100vh;
        color: #888683;
        background: -webkit-gradient(linear,left top,left bottom,from(#111),to(#222));
        background: linear-gradient(180deg,#111,#222);
        .request-page{
            padding-bottom: 24vw;
            .openpay-head-title{
                .openpay-head-title-icon{
                    background-image: url('../../assets/images/current_icon_yjjr@2x.5b3836f.png');
                    visibility: visible;
                    width: 12.8vw;
                    height: 12.8vw;
                    margin-right: 2.667vw;
                    background-size: 100% 100%;
                }
                
                height: 24.533vw;
                padding: 0 5.333vw 2.733vw;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                .openpay-head-title-text{
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    strong{
                        color: #ddba82;
                        font-size: 5.333vw;
                        font-weight: 600;
                    }
                    span{
                        color: #888683;
                        font-size: 3.2vw;
                        font-weight: 400;
                        margin-top: 0.8vw;
                    }
                }
            }
            .openpay-menu{
                height: 12.8vw;
                z-index: 1;
                position: relative;
                overflow: hidden;
                opacity: .85;
                border: 1px solid #3d3832;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-pack: distribute;
                justify-content: space-around;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                .menu-item {
                        font-size: 3.467vw;
                        font-weight: 400;
                        padding: 0 0.8vw;
                        color: #888683;
                        text-decoration: none;
                }
                .active{
                    color: #ddba82;
                }
            }
            .ef-title{
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                margin-top: 6.933vw;
                h1{
                    font-size: 4.267vw;
                    font-weight: 600;
                    color: #ddba82;
                    line-height: 7.467vw;
                }
                .ef-line{
                    width: 5.6vw;
                    height: 0.533vw;
                    background: #ddba82;
                    border-radius: 0.267vw;
                    margin: 2.667vw 0;
                }
            }
            .ef-detail-img{
                height: 81.6vw;
                padding: 5.333vw 6.667vw 14.667vw;
                margin-bottom: 8vw;
                overflow-x: scroll;
                overflow-y: hidden;
                .detail-img-con{
                    height: 100%;
                    max-width:none;
                }
                .icons{
                    width: 53.333vw;
                    position: absolute;
                    left: 24%;
                    margin-top: 4vw;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    .icon{
                        height: 2.133vw;
                        width: 2.133vw;
                    }
                    p{
                        font-size: 3.733vw;
                        color: #888683;
                        margin: 0 2.667vw;
                    }
                }
            }
            .ef-desc{
                .ef-desc-item {
                    border: 1px solid #3d3832;
                    border-radius: 0.8vw;
                    margin: 3.733vw 5.867vw 8vw;
                    padding: 0 4vw;
                    h1{
                        font-weight: 400;
                        margin-top: 4vw;
                        font-size: 4vw;
                        color: #2f4668;
                        line-height: 2.8vw;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        i{
                            font-size: 5.6vw;
                            line-height: 8vw;
                            color: #888683;
                            font-weight: 900;
                            margin-right: 2.667vw;
                            font-style: normal;
                            width: 8vw;
                            height: 8vw;
                        }
                        span{
                            color: #ddba82;
                            font-weight: 400;
                            font-size: 4vw;
                        }
                    }
                   
                    .ef-img-box{
                        border-radius: 0.533vw;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        margin: 3.467vw auto 3.2vw;
                        img{
                            width: 82.4vw;
                            height: 48vw;
                        }

                    }
                    p{
                        font-size: 3.2vw;
                        font-weight: 400;
                        color: #888683;
                        line-height: 5.6vw;
                        margin-bottom: 4.8vw;
                    }
                    .ef-icons{
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        margin-bottom: 5.333vw;
                        img{
                            width: 8.267vw;
                            height: 9.867vw;
                            margin: 0 4.667vw;
                        }
                        .apple{
                            width: 8.533vw;
                            height: 9.867vw;
                        }
                        .h5{
                            width: 8.267vw;
                            height: 9.6vw;
                        }
                    }
                    .ef-map{
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        width: 78.667vw;
                        height: 13.333vw;
                        font-size: 3.733vw;
                        font-weight: 600;
                        background: #ddba82;
                        border-radius: 1.867vw;
                        margin: 0 auto 5.333vw;
                        color: #2d2c2b;
                        .ef-map-box{
                            height: 4vw;
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            -webkit-box-pack: center;
                            -ms-flex-pack: center;
                            justify-content: center;
                        }
                        img{
                            width: 3.2vw;
                            height: 3.2vw;
                            margin-right: 2.667vw;
                        }
                        span{
                            display: inline;
                            height: 4vw;
                            line-height: 4vw;
                        }
                    }
                }
            }
        }
        .step-img{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            padding-top: 3.467vw;
            img{
                margin: 0 auto;
                width: 73.333vw;
                height: 109.333vw;
            }
        }
    }
}
</style>
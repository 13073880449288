<template>
    <div class="mobilde_document">
        <div class="ef-container">
            <top_mobile></top_mobile>
            <div class="request-page">
                <div class="openpay-head-title">
                    <div class="openpay-head-title-icon">
                    </div>
                    <div class="openpay-head-title-text">
                        <strong>{{ $t('message.solution_zyxz') }}</strong>
                    </div>
                </div>
                <div class="openpay-menu">
                    <a @click="$router.push('/mobile_solution')" class="menu-item">{{ $t('message.home_jjfan') }}</a>
                        <a @click="$router.push('/mobile_scenario')" class="menu-item ">{{ $t('message.solution_yycj') }}</a>
                        <a @click="$router.push('/mobilde_document')" class="menu-item">{{ $t('message.solution_kfwd') }}</a>
                        <a class="menu-item active">{{ $t('message.solution_zyxz') }}</a>
                </div>
                <div class="document-page">
                    <div class="icon-document-search">
                    </div>
                    <div class="tip-box">
                      {{ $t('message.solution_fcbqwmkf') }}<br>
                      {{ $t('message.solution_qywmshlx') }}
                         <span class="bottom-tip">{{ $t('message.solution_wmhjkbfkf') }}</span>
                    </div>
                </div>
                <footer_mobile></footer_mobile>
            </div>
        </div>
    </div>
</template>
<script>
import top_mobile from "@/components/mobile/mobile_top.vue"
import footer_mobile from "@/components/mobile/mobile_down.vue"
export default {
    name: 'mobilde_document',
    components: {
        footer_mobile,
        top_mobile
  },
  data() {
    return {
    }
    },
    methods:{
    }
}
</script>
<style lang="scss">
.mobilde_document{
    .ef-container{
        margin: 0 auto;
        min-height: 100vh;
        color: #888683;
        background: -webkit-gradient(linear,left top,left bottom,from(#111),to(#222));
        background: linear-gradient(180deg,#111,#222);
        .request-page{
            padding-bottom: 24vw;
            .openpay-head-title{
                .openpay-head-title-icon{
                    background-image: url('../../assets/images/download@3x.f9870bf.png');
                    visibility: visible;
                    width: 12.8vw;
                    height: 12.8vw;
                    margin-right: 2.667vw;
                    background-size: 100% 100%;
                }
                
                height: 24.533vw;
                padding: 0 5.333vw 2.733vw;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                .openpay-head-title-text{
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    strong{
                        color: #ddba82;
                        font-size: 5.333vw;
                        font-weight: 600;
                    }
                    span{
                        color: #888683;
                        font-size: 3.2vw;
                        font-weight: 400;
                        margin-top: 0.8vw;
                    }
                }
            }
            .openpay-menu{
                height: 12.8vw;
                z-index: 1;
                position: relative;
                overflow: hidden;
                opacity: .85;
                border: 1px solid #3d3832;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-pack: distribute;
                justify-content: space-around;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                .menu-item {
                        font-size: 3.467vw;
                        font-weight: 400;
                        padding: 0 0.8vw;
                        color: #888683;
                        text-decoration: none;
                }
                .active{
                    color: #ddba82;
                }
            }
        .document-page{
            padding-bottom: 24vw;
            .icon-document-search{
                margin: 18.4vw auto 6.133vw;
                background-position: 50%;
            background-size: contain;
            background-repeat: no-repeat;
            width: 15.467vw;
            height: 14.933vw;
            background-image: url(../../assets/images/default_kfwd.png);
            }
            .tip-box{
                font-size: 3.2vw;
                font-weight: 400;
                color: #888683;
                text-align: center;
            }
            .bottom-tip{
                text-align: center;
                font-size: 3.733vw;
                font-weight: 400;
                color: #ddba82;
                display: block;
            }
        }
        }
    }
}
</style>
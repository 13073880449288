import { render, staticRenderFns } from "./download.vue?vue&type=template&id=4f32965a&"
import script from "./download.vue?vue&type=script&lang=js&"
export * from "./download.vue?vue&type=script&lang=js&"
import style0 from "./download.vue?vue&type=style&index=0&data-vue-ssr-id=b1f5444e%3A0%207f914dc6%3A0%20d88f92a8%3A0%20528b6fe6%3A0%201019a922%3A0%2086a2a5fc%3A0%200c79af48%3A0%2012bd8e83%3A0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
    <div class="mobile_scenario">
        <div class="ef-container">
            <top_mobile></top_mobile>
            <div class="request-page">
                <div class="openpay-head-title">
                    <div class="openpay-head-title-icon">
                    </div>
                    <div class="openpay-head-title-text">
                        <strong>{{ $t('message.solution_qcjfg') }}</strong>
                        <span>{{ $t('message.solution_zlndsyxsl') }}</span>
                    </div>
                </div>
              <div class="openpay-menu">
                <a @click="$router.push('/mobile_solution')" class="menu-item">{{ $t('message.home_jjfan') }}</a>
                <a @click="$router.push('/mobile_scenario')" class="menu-item ">{{ $t('message.solution_yycj') }}</a>
                <a @click="$router.push('/mobilde_document')" class="menu-item">{{ $t('message.solution_kfwd') }}</a>
                <a class="menu-item active">{{ $t('message.solution_zyxz') }}</a>
              </div>
                
                    <div class="ef-title">
                        <h1>{{ $t('message.solution_zxfw') }}</h1>
                        <span class="ef-line"></span>
                    </div>
                    <div class="ef-desc">
                        <div class="ef-desc-item">
                            <img src="../../assets/images/online_service_gw.png">
                            <div class="ef-item-right">
                                <h1>{{ $t('message.solution_gw') }}</h1>
                                <p>{{ $t('message.solution_xssco2o') }}</p>
                            </div>
                        </div>
                    <!-- </div> -->
                    <!-- <div class="ef-desc"> -->
                        <div class="ef-desc-item">
                            <img src="../../assets/images/online_service_jf.png">
                            <div class="ef-item-right">
                                <h1>{{ $t('message.solution_jf') }}</h1>
                                <p>{{ $t('message.solution_czfkzf') }}</p>
                            </div>
                        </div>
                    <!-- </div> -->
                    <!-- <div class="ef-desc"> -->
                        <div class="ef-desc-item">
                            <img src="../../assets/images/online_service_jr.png">
                            <div class="ef-item-right">
                                <h1>{{ $t('message.solution_jrr') }}</h1>
                                <p>{{ $t('message.solution_lcjkdb') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="ef-title">
                        <h1>{{ $t('message.solution_qtt') }}</h1>
                        <span class="ef-line"></span>
                    </div>
                    <div class="ef-desc">
                        <div class="ef-desc-item">
                            <img src="../../assets/images/online_service_gw.png">
                            <div class="ef-item-right">
                                <h1>loT</h1>
                                <p>{{ $t('message.solution_wlsbzdjs') }}</p>
                            </div>
                        </div>
                    <!-- </div> -->
                    <!-- <div class="ef-desc"> -->
                        <div class="ef-desc-item">
                            <img src="../../assets/images/online_service_jf.png">
                            <div class="ef-item-right">
                                <h1>{{ $t('message.solution_stsyy') }}</h1>
                                <p>{{ $t('message.solution_sdskyywdjf') }}</p>
                            </div>
                        </div>
                    <!-- </div> -->
                    <!-- <div class="ef-desc"> -->
                        <div class="ef-desc-item">
                            <img src="../../assets/images/online_service_jr.png">
                            <div class="ef-item-right">
                                <h1>{{ $t('message.solution_grr') }}</h1>
                                <p>{{ $t('message.solution_pyzzfhb') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="ef-more">
                        <div class="ef-title">
                            <h2>{{ $t('message.solution_gdyyfs') }}</h2>
                            <h3>{{ $t('message.solution_ddngtkf') }}</h3>
                            <span class="ef-line"></span>
                        </div>
                    </div>
            </div>
            <footer_mobile></footer_mobile>
        </div>
    </div>
</template>
<script>
import top_mobile from "@/components/mobile/mobile_top.vue"
import footer_mobile from "@/components/mobile/mobile_down.vue"
export default {
    name: 'mobile_scenario',
    components: {
        footer_mobile,
        top_mobile
  },
  data() {
    return {
    }
    },
    methods:{
    }
}
</script>
<style lang="scss">
.mobile_scenario{
    .ef-container{
        margin: 0 auto;
        min-height: 100vh;
        color: #888683;
        background: -webkit-gradient(linear,left top,left bottom,from(#111),to(#222));
        background: linear-gradient(180deg,#111,#222);
        .request-page{
            padding-bottom: 24vw;
            .openpay-head-title{
                .openpay-head-title-icon{
                    background-image: url('../../assets/images/current_icon_yycj@2x.a035643.png');
                    visibility: visible;
                    width: 12.8vw;
                    height: 12.8vw;
                    margin-right: 2.667vw;
                    background-size: 100% 100%;
                }
                
                height: 24.533vw;
                padding: 0 5.333vw 2.733vw;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                .openpay-head-title-text{
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    strong{
                        color: #ddba82;
                        font-size: 5.333vw;
                        font-weight: 600;
                    }
                    span{
                        color: #888683;
                        font-size: 3.2vw;
                        font-weight: 400;
                        margin-top: 0.8vw;
                    }
                }
            }
            .openpay-menu{
                height: 12.8vw;
                z-index: 1;
                position: relative;
                overflow: hidden;
                opacity: .85;
                border: 1px solid #3d3832;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-pack: distribute;
                justify-content: space-around;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                .menu-item {
                        font-size: 3.467vw;
                        font-weight: 400;
                        padding: 0 0.8vw;
                        color: #888683;
                        text-decoration: none;
                }
                .active{
                    color: #ddba82;
                }
            }
            .ef-title{
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                margin-top: 6.933vw;
                h1{
                    font-size: 4.267vw;
                    font-weight: 600;
                    color: #ddba82;
                    line-height: 7.467vw;
                }
                .ef-line{
                    width: 5.6vw;
                    height: 0.533vw;
                    background: #ddba82;
                    border-radius: 0.267vw;
                    margin: 2.667vw 0;
                }
            }
            .ef-desc{
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                .ef-desc-item{
                    width: 89.333vw;
                    height: 21.333vw;
                    z-index: 1;
                    position: relative;
                    overflow: hidden;
                    opacity: .85;
                    border-radius: 2.133vw;
                    border: 1px solid #3d3832;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    padding-left: 5.333vw;
                    margin: 0 auto 6.4vw;
                    img{
                        width: 11.467vw;
                        height: 11.467vw;
                        margin-right: 3.2vw;
                    }
                    .ef-item-right{
                        h1{
                            font-size: 3.733vw;
                            font-weight: 400;
                            color: #ddba82;
                            line-height: 3.733vw;
                            margin-bottom: 1.867vw;
                        }
                        p{
                            width: 60.267vw;
                            height: 2.933vw;
                            font-size: 3.2vw;
                            font-weight: 400;
                            color: #888683;
                            line-height: 3.2vw;
                        }
                    }
                }
            }
            .ef-more{
                width: 89.333vw;
                height: 47.733vw;
                border-radius: 2.133vw;
                border: 1px solid #3d3832;
                margin: 0 auto;
                .ef-title{
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    margin-top: 6.933vw;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    h2{
                        font-size: 3.733vw;
                        color: #ddba82;
                        line-height: 6.4vw;
                        font-weight: 400;
                        text-align: center;
                    }
                    h3{
                        font-size: 3.2vw;
                        color: #888683;
                        line-height: 6.4vw;
                    }
                    .ef-line{
                        width: 8vw;
                        height: 0.533vw;
                        background: #ddba82;
                        border-radius: 0.267vw;
                        margin: 2.667vw 0 6.4vw;
                    }
                }
            }
        }
    }
}
</style>
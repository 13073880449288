<template>
    <div class="mobilde_download">
        <top_mobile></top_mobile>
        <div class="content">
            <div class="download-box">
                <div class="header">
                    <h1 class="color-text-primary">{{ $t('message.qkljxcuks') }}</h1>
                    <p class="color-text-grey">{{ $t('message.xzqxzb') }}</p>
                </div>
                <div class="ef-img-box">
                    <img class="app-money-img" :src="app_phone1">
                    <img class="ef-show-img" :src="app_phone">
                    <img class="ef-icon ef-icon1" :src="app_phone2">
                </div>
                </div>
                <!-- <div class="tip-box"></div> -->
                <div class="btns-box">
                    <div class="btns btns-fixed">
                        <div class="donwloadBtn">
                            <div @click="goUb()" class="downFun">
                                <img src="../../assets/images/home_app_logo.png"/>
                                <span>{{ $t('message.xzapp') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import top_mobile from "@/components/mobile/mobile_top.vue"
export default {
    name: 'mobilde_download',
    components: {
        top_mobile
  },
  data() {
    return {
    }
    },
    computed: {
      app_phone() {
        return require(`@/assets/images/${this.$t('message.app_phone')}`);
      },
      app_phone1() {
        return require(`@/assets/images/${this.$t('message.app_phone1')}`);
      },
      app_phone2() {
        return require(`@/assets/images/${this.$t('message.app_phone2')}`);
      },
    },
    methods:{
        goUb(){
        window.open('https://www.yubituser.com','_blank')
    },
    }
}
</script>
<style lang="scss">
.mobilde_download{
    margin: 0 auto;
        min-height: 100vh;
        color: #888683;
        background: -webkit-gradient(linear,left top,left bottom,from(#111),to(#222));
        background: linear-gradient(180deg,#111,#222);
        .content{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-direction: normal;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            .download-box{
                .header{
                    margin-top: 2.667vw;
                    .color-text-primary{
                        font-size: 5.333vw;
                        font-weight: 600;
                        text-align: center;
                        color: #ddba82;
                    }
                    .color-text-grey{
                        font-size: 3.2vw;
                        margin-bottom: 2.4vw;
                        color: #888683;
                        text-align: center;
                    }
                }
                .ef-img-box{
                    width: 100vw;
                    position: relative;
                    .app-money-img{
                        animation: ol-baloon-7c9d956b 3s 0s infinite;
                        position: absolute;
                        left: 12.621vw;
                        width: 81.512vw;
                        margin: 1.333vw auto 0;
                        display: block;
                        top: 32.024vw;
                    }
                    .ef-show-img{
                        width: 61.765vw;
                        margin: 1.333vw auto 0;
                        display: block;
                    }
                    .ef-icon{
                        animation: ol-baloon-7c9d956b 3s .3s infinite;
                        left: 5.867vw;
                        top: 53.576vw;
                        width: 85vw;
                        position: absolute;
                    }
                }
            }
            .btns-box{
                width: 100%;
                height: 13.333vw;
                position: fixed;
                z-index: 9999;
                bottom: 0;
                .btns-fixed{
                    width: 100%;
                    
                    padding-bottom: env(safe-area-inset-bottom);
                    .donwloadBtn{
                        height: 13.333vw;
                        background: #ddba82;
                        border-radius: 1.067vw;
                        color: #2d2c2b;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-transition: all .2s ease-in-out;
                        transition: all .2s ease-in-out;
                        .downFun{
                            width: 100%;
                            height: 100%;
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-direction: normal;
                            -webkit-box-pack: center;
                            -ms-flex-pack: center;
                            justify-content: center;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            img{
                                width: 4.8vw;
                                height: 4.8vw;
                                margin-right: 1.6vw;
                            }
                            span{
                                font-weight: 600;
                                font-size: 3.467vw;
                                color: #2d2c2b;
                            }
                        }
                    }
                }
            }
        }
        @keyframes ol-baloon-7c9d956b {
        0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
50% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
}

100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
}
}
</style>